#footer {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding: 2rem 1rem;
    text-align: center;
}

#footer .footer-logo {
    max-width: 150px;
}
