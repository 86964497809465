header {
    font-family: "DIN Condensed", serif;
}

header .app-bar {

    background-color: rgba(0,0,0,0);
    box-shadow: none;

}

header img.logo  {
    max-width: 150px;
}


