@font-face {
    font-family: CitrusGothicSolid-Regular;
    src: url(./fonts/CitrusGothic-Regular.ttf);
}

* {
    color: #FAF7ED !important;
}
a {
    text-decoration: none;
}

header {
    max-width: 1390px;
    height: 140px;
    margin-right: auto;
    margin-left: auto;
}

/*main {*/
/*    margin-top: 140px;*/
/*}*/



